
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({ name: "Login" })
export default class extends Vue {
  @Prop() value: any;
  @Prop() outlined?: boolean;
  @Prop() label?: string;
  @Prop() color?: string;
  @Prop() solo?: boolean;
  @Prop() rules?: any[];
  @Prop() disabled?: boolean;
  @Prop() dense?: boolean;

  menu = false;
  model = '';

  get inputValue() {
    if (!this.model || !this.model.split(':')) {
      return '';
    }
    
    const hour = Number(this.model.split(':')[0]);
    const minute = this.model.split(':')[1];
    const formattedMinute = minute.length === 1 ? `0${minute}` : minute;

    return hour > 12 ? `${hour - 12}:${formattedMinute} PM` : `${hour}:${formattedMinute} AM`;
  }

  mounted() {
    this.model = this.value;
  }

  save() {
    this.$emit('input', this.model);
    this.menu = false;
  }
}
