
import { Component, Vue } from 'vue-property-decorator';
import { pick, clone } from 'ramda';
import { randomBytes } from 'crypto';
import moment from 'moment';
import axios from 'axios';
import imageCompression from 'browser-image-compression';

import { Auth } from '@/store/modules';

import DatePicker from '@/components/DatePicker.vue';
import TimePicker from '@/components/TimePicker.vue';
import ImageUploadDialog from '@/components/ImageUploadDialog.vue';
import { isMaterialsKitEnabled } from '@/misc/featureFlags';

@Component({
  components: {
    DatePicker,
    TimePicker,
    ImageUploadDialog,
  },
})
export default class ClassEdit extends Vue {
  loading = true;
  errorLoading = '';
  model: any = {
    name: '',
    startDate: '',
    categoryId: '',
    media: [],
    details: {
      hasRequirements: false,
      hasMaterials: false,
      requirements: '',
      materials: '',
      desc: '',
    },
    meetings: [{
      name: '',
      date: '',
      length: 30
    }] as any[],
    fee: 10,
    feeForMaterials: 0,
    maxSeats: 25,
    materialsAllowedCountries: ['IN'],
  };
  loadingSave = false;
  isFileInputLoading = false;
  inputPhotos = this.model.media;
  errorSave = '';
  required = (v: any) => `${v}` !== '' || 'This field is required.';
  seriesRule = (v: number) => v >= 2 || 'Please enter at least 2';
  classLength = (v: number) => v >= 20 || 'Class must be at least 20 minutes long';
  maxSeatsRule = (v: number) => (v >= 1 && v <= 199) || 'Enter a number from 1 to 199';
  nameLength = (v: string) => (v && v.length < 64) || 'Class name should not exceed 64 characters.';
  feeRule = (v: number) => (v == 0 || v >= 3) || 'Fee must be at least $3. To create a free class enter 0 as a class fee.';
  freeClassWithMaterialsRule = (model: any) => (v: number) => ((v == 0 && !model.details.hasMaterials) || v >= 3) || 'You cannot create a free class with Material Kit.';
  requiredForMaterials = (model: any) => (v: any) => (`${v}` !== '' || !model.details.hasMaterials) || 'This field is required.'
  feeForMaterialsRule = (v: number) => (v != 0) || 'Fee for Material Kit needs to be higher than 0.';
  loadingCategories = false; 
  categories: any[] = [];
  subcategories: any[] = [];
  materialsAllowedCountriesList: any[] = [
    { text: 'India', value: 'IN',  }
  ];
  categoryId = '';
  subcategoryId = '';
  defaultUploadId = `${(Auth.user as any).teacherId}-${Date.now()}`;
  classTypes = [
    { text: 'Single Event', value: false },
    { text: 'Series of Classes', value: true },
  ];
  series = false;
  seriesInput = 1;
  classId = undefined;
  featureFlagMaterialsKit: boolean = false;

  getFinalDate(date_: string, time: string) {
    return moment(`${date_} ${time}`).toISOString();
  }

  get uploadId() {
    return this.model.id || this.defaultUploadId;
  }

  get duplicateId() {
    return this.$route.query.duplicate;
  }

  get hasMedia () {
    return this.model.media && this.model.media.length > 0
  }

  get videoMedia() {
    return this.model.media.find((item: any) => item.type === 'video') || { url: '', type: 'video', main: false };
  }

  async mounted() {
    await Auth.getSelfInformation();
    if (!Auth.finishedTeacherOnboarding) {
      window.alert("You have not finished setting up your account yet. You must finish onboarding before you will be able to create classes.");
      this.$router.push('/public-account');
    }

    this.featureFlagMaterialsKit = isMaterialsKitEnabled();

    await this.fetchCategories();

    if (!this.isEdit && !this.duplicateId) {
      const classType = this.$route.query.type;
      
      if (classType === 'single') {
        this.series = false;
      }

      if (classType === 'series') {
        this.series = true;
      }

      this.loading = false;
      return;
    }

    try {
      const response = await Vue.$axios.get(`/classes/${this.classSlug || this.duplicateId}`);
      this.model = clone(response.data);
      this.classId = this.model.id;
      this.model.maxSeats -= 1;
      this.model.meetings.forEach((meeting: any, key: number) => {
        const time = moment(meeting.date).format('HH:mm');
        this.model.meetings[key].time = time;

        if (!this.duplicateId) {
          const date = moment(meeting.date).format('YYYY-MM-DD');
          this.model.meetings[key].date = date;
          this.model.meetings[key].edit = true;
        } else {
          this.model.meetings[key].date = '';
          this.model.meetings[key].edit = false;
        }
      });
      this.sortMeetings();
      const categoryRes = await Vue.$axios.get(`/categories/${this.model.categoryId}`);
      this.categoryId = categoryRes.data.path[0] || this.model.categoryId;
      this.series = this.model.meetings.length > 1;
      this.seriesInput = this.model.meetings.length;
      await this.fetchCategories();
      this.subcategoryId = categoryRes.data.path[0] ? this.model.categoryId : '-';
    } catch (err) {
      this.errorLoading = '';
    }
    this.loading = false;
  }

  sortMeetings() {
    this.model.meetings.sort((a: any, b: any) => new Date(a.date).valueOf() - new Date(b.date).valueOf());
  }

  get requirements() {
    return (this.model && this.model.details && this.model.details.requirements && this.model.details.requirements.split('\n')) || [];
  }

  get materials() {
    return (this.model && this.model.details && this.model.details.materials && this.model.details.materials.split('\n')) || [];
  }

  get classSlug() {
    return this.$route.params.slug;
  }

  get isEdit() {
    return !!this.classSlug;
  }

  async save() {
    this.loadingSave = true;
    this.errorSave = '';
    try {
      if (!(this.$refs as any).form.validate()) {
        throw Error('Please fill in required fields');
      }
      
      const meetings = this.series ? this.model.meetings.filter((meeting: any) => !meeting.edit).map((meeting: any) => ({
          name: meeting.name,
          date: this.getFinalDate(meeting.date, meeting.time),
          length: meeting.length
        })) : this.isEdit ? [] : [{
          name: this.model.name,
          date: this.getFinalDate(this.model.meetings[0].date, this.model.meetings[0].time),
          length: this.model.meetings[0].length
        }];

      if (meetings.find((meeting: any) => new Date(meeting.date).valueOf() < Date.now())) {
        this.errorSave = 'At least one of the classes start in the past.';
        this.loadingSave = false;
        return;
      }

      this.setVideoMedia();

      const toPick = this.isEdit ? ["name", "details", "media"] : this.model.details.hasMaterials ? ["name", "details", "maxSeats", "fee", "media", "feeForMaterials", "materialsAllowedCountries"] : ["name", "details", "maxSeats", "fee", "media"];
      const maxSeats = this.isEdit ? {} : { maxSeats: Number(this.model.maxSeats) + 1 };
      const toSave = {
        ...pick(toPick, this.model),
        ...maxSeats,
        categoryId: (this.subcategoryId && this.subcategoryId !== '-') ? this.subcategoryId : this.categoryId,
        meetings,
        details: {
          ...this.model.details,
          hasRequirements: !!this.model.details.requirements,
          hasMaterials: !!this.model.details.materials,
        }
      };

      if (this.isEdit) {
        const updated = await this.$axios.patch(`/classes/${this.classId}`, toSave);
        this.$router.push(`/classes/${updated.data.id}`);
      } else {
        const created = await this.$axios.post('/classes', toSave);
        this.$router.push(`/classes/${created.data.id}`);
      }
    } catch (err) {
      this.errorSave = 'Failed to save Class. Please verify if the data you entered above is correct.';
    }
    this.loadingSave = false;
  }

  async fetchCategories() {
    this.loadingCategories = true;
    try {
      const categoriesResponse = await Vue.$axios.get('/categories');
      this.categories = categoriesResponse.data;

      if (this.categoryId) {
        const subcategoriesResponse = await Vue.$axios.get(`/categories?path=${this.categoryId}`);
        this.subcategories = [...subcategoriesResponse.data, { name: 'Others', id: '-' }];
      }
    } catch (err) {
      console.warn(err.message);
    }
    this.loadingCategories = false;
  }

  setClassMedia (url: string, isMain = true, index = 0) {
    const uploadedPhoto = {
      url,
      main: isMain,
      type: 'image'
    }
    if (isMain) {
      this.$set(this.model.media, 0, uploadedPhoto) 
    } else {
      this.$set(this.model.media, index, uploadedPhoto)
    }
  }

  setVideoMedia() {
    const existingVideoMedia = this.model.media.find((media: any) => media.type === 'video');

    if (existingVideoMedia) {
      if (!existingVideoMedia.url.length) {
        this.model.media = this.model.media.filter((media: any) => media.type !== 'video');
      }
      return;
    }

    if (this.videoMedia.url.length) {
      this.model.media.push(this.videoMedia);
    }
  }

  handleFileClear () {
    this.inputPhotos = []
    this.$set(this.model, 'media', [])
  }

  async handleFileChange(files: File[]) {
    this.inputPhotos = files
    this.isFileInputLoading = true
    await Promise.all(files.map(async (file, i) => {
      const compressedFile = await imageCompression(file, { maxSizeMB: 1 });
      const uploadUrl = await Auth.getUploadUrl({
        type: "class_image",
        id: `${this.uploadId}-${randomBytes(2).toString('hex')}`,
      });

      const imgUrl = `${uploadUrl.substr(0, uploadUrl.indexOf('?'))}?q=${Date.now()}`
      this.setClassMedia(imgUrl, i === 0, i)

      await axios.put(uploadUrl, compressedFile, {
        headers: {
          'Content-Type': compressedFile.type || 'image/jpeg',
        }
      });
    }))
    this.isFileInputLoading = false
  }

  setMeetings() {
    if (this.seriesInput < 1) {
      this.seriesInput = 1;
    }

    if (this.seriesInput > 20) {
      this.seriesInput = 20;
      window.alert("Maximum number of classes is 20");
    }

    const emptyMeeting = {
      name: '',
      date: '',
      time: '',
      length: 0
    };

    const currentLength = this.model.meetings.length;

    if (this.seriesInput > currentLength) {
      for (let i = 0; i < this.seriesInput - currentLength; i++) {
        this.model.meetings.push(clone(emptyMeeting));
      }
    } else {
      for (let i = 0; i < currentLength - this.seriesInput; i++) {
        const meeting = this.model.meetings[this.model.meetings.length - 1];
        if (meeting.name || meeting.date || meeting.time || meeting.length) {
          const confirmed = confirm(`Do you want to delete Class ${this.model.meetings.length}?`);
          if (!confirmed) {
            this.seriesInput = this.model.meetings.length;
            break;
          }
        }
        this.model.meetings.splice(this.model.meetings.length - 1, 1);
      }
    }

    this.$forceUpdate();
  }

  addMeeting() {
    this.model.meetings.push({
      name: '',
      date: '',
      time: '',
      length: 0
    });
    this.$forceUpdate();
  }

  removeMeeting(id: number) {
    this.model.meetings.splice(id, 1);
    this.$forceUpdate();
  }

  cancel() {
    const reallyCancel = confirm("Do you want to cancel? All changes you made to the class won't be saved.");

    if (reallyCancel) {
      this.$router.go(-1);
    }
  }

  copyMaterialsFromRequirements(state: boolean) {
    if (state == true && !this.model.details.materials) {
      this.model.details.materials = this.model.details.requirements;
    }
  }
}
