import { render, staticRenderFns } from "./CreateClass.vue?vue&type=template&id=6d60a9c8&scoped=true&lang=pug&"
import script from "./CreateClass.vue?vue&type=script&lang=ts&"
export * from "./CreateClass.vue?vue&type=script&lang=ts&"
import style0 from "./CreateClass.vue?vue&type=style&index=0&id=6d60a9c8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d60a9c8",
  null
  
)

export default component.exports